import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';

import {
  API_URL,
  sendAuthenticatedGetRequest,
  sendAuthenticatedPostRequest,
  sendAuthenticatedPutRequest,
} from '../../../services/http';

import InputField from '../../ui/inputField';
import DropDownField from '../../ui/dropdown';
import {
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  TOAST_STATUS,
  UPDATE_USER_SUCCESS,
  USER_ROLE_DROPDOWN,
  USER_ROLE_ORGANIZATION,
} from '../../../Constants';

interface UserData {
  userId?: string;
  name?: string;
  email?: string;
  role?: string;
  organizationName?: string;
  organizationTypeName?: string;
  specifiedCategory?: string;
}

interface UserFormProps {
  isEdit?: boolean;
  userData?: UserData;
}

export default function UserForm({ isEdit = false, userData }: UserFormProps) {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [orgTypes, setOrgTypes] = useState<any>('');
  const [orgTypeId, setOrgTypeId] = useState('');

  const UserSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().trim().email('Email must be valid').required('Email address is required'),
    userRole: Yup.string().required('User Role is required'),
    organizationName: Yup.string().when('userRole', {
      is: (value: string) => value && value === USER_ROLE_ORGANIZATION,
      then: Yup.string().required('Organization Name is required'),
    }),
    password: isEdit
      ? Yup.string()
      : Yup.string()
          .required('Password is required')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*/=?])(?=.{8,})/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
          ),
    organizationType: Yup.string().when('userRole', {
      is: (value: string) => value && value === USER_ROLE_ORGANIZATION,
      then: Yup.string().required('Organization Type is required'),
    }),
    specifyType: Yup.string().when('organizationType', {
      is: (value: string) => value && value === orgTypeId,
      then: Yup.string().required('Specify Organization Type'),
    }),
  });

  const getOrgTypes = async () => {
    await sendAuthenticatedGetRequest(`${API_URL}/organizationTypes`)
      .then((orgTypesReq: any) => {
        for (let i = 0; i < orgTypesReq.data.organizationTypes.length; i++) {
          if (orgTypesReq.data.organizationTypes[i].name === 'Other') {
            setOrgTypeId(orgTypesReq.data.organizationTypes[i].id);
          }
        }
        setOrgTypes(orgTypesReq.data.organizationTypes);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrgTypes();
  }, []);

  return orgTypes.length === 0 ? (
    <div className="flex justify-center items-center h-screen">
      <CircularProgress />
    </div>
  ) : (
    <div>
      <Formik
        initialValues={{
          name: userData?.name || '',
          email: userData?.email || '',
          userRole: userData?.role || '',
          organizationName: userData?.organizationName || '',
          password: '',
          organizationType: userData?.organizationTypeName
            ? orgTypes.find((org: any) => org.name === userData.organizationTypeName)?.id || ''
            : '',
          specifyType: userData?.specifiedCategory || '',
        }}
        validationSchema={UserSchema}
        onSubmit={async values => {
          setLoading(true);
          const data = {
            name: values.name,
            email: values.email,
            password: values.password || undefined, // Don't send password if not set
            role: values.userRole,
            organization: {
              organizationName: values.organizationName,
              organizationTypeId: values.organizationType,
              specifiedCategory: values.specifyType,
            },
          };

          const request = isEdit
            ? sendAuthenticatedPutRequest(
                `${API_URL}/users/edit/${userData?.userId}`,
                JSON.stringify(data)
              )
            : sendAuthenticatedPostRequest(`${API_URL}/users/create`, JSON.stringify(data));

          await request
            .then(() => {
              setLoading(false);
              history.push({
                pathname: '/users',
                state: {
                  toastStatus: TOAST_STATUS,
                  toastMessage: isEdit ? UPDATE_USER_SUCCESS : ADD_USER_SUCCESS,
                  severity: 'success',
                },
              });
            })
            .catch(err => {
              setLoading(false);
              history.push({
                pathname: '/users',
                state: {
                  toastStatus: TOAST_STATUS,
                  toastMessage: isEdit ? err.response.data.message : ADD_USER_FAILED,
                  severity: 'error',
                },
              });
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <div>
            <div className="flex justify-center">
              <div className="w-full">
                <div>
                  <span className="font-semibold text-gray-700 text-2xl">
                    {isEdit ? 'Update User' : 'Add User'}
                  </span>
                  <div className="pb-10 border-b-2 border-gray-300">
                    <div className="font-semibold text-gray-700 text-xl py-4">Personal Details</div>
                    <div className="mb-6 border-b-2 border-gray-300">
                      <div>
                        <InputField
                          title={'Name'}
                          placeholder={'Enter first name'}
                          containerClassNames={
                            'font-small md:text-left md:mb-0 align-middle text-black-500 py-2'
                          }
                          onChangeHandler={handleChange('name')}
                          inputClassNames={'w-1/2'}
                          isRequired={true}
                          value={values.name}
                          formikError={errors.name}
                          formikTouch={touched.name}
                        />
                      </div>
                      <div>
                        <InputField
                          title={'Email address'}
                          placeholder={'rootcodelabs@example.com'}
                          type={'email'}
                          containerClassNames={
                            'font-small md:text-left md:mb-6 align-middle text-black-500 py-2'
                          }
                          onChangeHandler={handleChange('email')}
                          inputClassNames={'w-1/2'}
                          isRequired={true}
                          value={values.email}
                          formikError={errors.email}
                          formikTouch={touched.email}
                        />
                      </div>
                    </div>
                    <div className="font-semibold text-gray-700 text-xl py-4">
                      Account Management
                    </div>
                    <div>
                      <div className="flex w-full relative flex-col">
                        <div className="flex py-2">
                          <DropDownField
                            isRequired={true}
                            title="User role"
                            value={values.userRole}
                            data={USER_ROLE_DROPDOWN}
                            onChangeHandler={handleChange('userRole')}
                            formikError={errors.userRole}
                            formikTouch={touched.userRole}
                            isVertical={true}
                            containerClassNames={'w-1/2 pr-10'}
                            inputClassNames={'w-full'}
                            titleClassNames={'mb-1'}
                          />
                          <InputField
                            title={'Password'}
                            isRequired={!isEdit}
                            placeholder={'password'}
                            type={'password'}
                            value={values.password}
                            onChangeHandler={handleChange('password')}
                            isHorizontal={false}
                            inputClassNames={'w-full'}
                            containerClassNames={'w-1/2'}
                            formikError={errors.password}
                            formikTouch={touched.password}
                          />
                        </div>
                      </div>

                      {values.userRole === USER_ROLE_ORGANIZATION && (
                        <>
                          <div className="flex w-full relative flex-col">
                            <div className="flex py-2">
                              <InputField
                                title={'Organization Name'}
                                placeholder={'Rootcode labs'}
                                isHorizontal={false}
                                inputClassNames={'w-full'}
                                containerClassNames={'w-1/2 pr-10'}
                                isRequired={true}
                                onChangeHandler={handleChange('organizationName')}
                                value={values.organizationName}
                                formikError={errors.organizationName}
                                formikTouch={touched.organizationName}
                              />
                              <DropDownField
                                isRequired={true}
                                title="Organization Type"
                                value={values.organizationType}
                                data={orgTypes}
                                onChangeHandler={handleChange('organizationType')}
                                formikError={errors.organizationType}
                                formikTouch={touched.organizationType}
                                isVertical={true}
                                containerClassNames={'w-1/2'}
                                inputClassNames={'w-full'}
                                titleClassNames={'mb-1'}
                              />
                            </div>
                          </div>
                          {values.organizationType === orgTypeId && (
                            <div className="flex w-full relative flex-col">
                              <div className="flex py-2">
                                <InputField
                                  title={'Specify Organization Type'}
                                  isHorizontal={false}
                                  inputClassNames={'w-full'}
                                  containerClassNames={'w-1/2 pr-10'}
                                  isRequired={true}
                                  onChangeHandler={handleChange('specifyType')}
                                  value={values.specifyType}
                                  formikError={errors.specifyType}
                                  formikTouch={touched.specifyType}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end mt-10">
                    <button
                      className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base"
                      type="button"
                      onClick={() => history.replace({ pathname: '/users' })}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 flex justify-center items-center"
                      type="submit"
                      disabled={loading}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : isEdit ? (
                        'Update'
                      ) : (
                        'Add'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
