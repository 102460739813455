export const USER_ROLE_ADMIN = 'ADMIN';
export const USER_ROLE_MONITOR = 'MONITOR';
export const USER_ROLE_ORGANIZATION = 'ORGANIZATION';
export const ADD_INCIDENT = 'ADD INCIDENT';
export const STATUS_NONE = 'NONE';
export const STATUS_ALL = 'ALL';
export const STATUS_APPROVED = 'APPROVED';
export const STATUS_PENDING = 'PENDING';
export const STATUS_REJECTED = 'REJECTED';
export const COUNTRY_SRILANKA = 'Sri Lanka';

export const SRILANKA_PROVINCES = [
  { id: 'Central', name: 'Central' },
  { id: 'Eastern', name: 'Eastern' },
  { id: 'North Central', name: 'North Central' },
  { id: 'North Western', name: 'North Western' },
  { id: 'Northern', name: 'Northern' },
  { id: 'Sabaragamuwa', name: 'Sabaragamuwa' },
  { id: 'Southern', name: 'Southern' },
  { id: 'Uva', name: 'Uva' },
  { id: 'Western', name: 'Western' },
];

export const SRILANKA_DISTRICTS: { [unit: string]: any } = {
  Central: [
    { id: 'lk-ky', name: 'Kandy' },
    { id: 'lk-mt', name: 'Matale' },
    { id: 'lk-nw', name: 'Nuwara Eliya' },
  ],
  Eastern: [
    { id: 'lk-ap', name: 'Ampara' },
    { id: 'lk-bc', name: 'Batticaloa' },
    { id: 'lk-tc', name: 'Trincomalee' },
  ],
  North_Central: [
    { id: 'lk-ad', name: 'Anuradhapura' },
    { id: 'lk-pr', name: 'Polonnaruwa' },
  ],
  North_Western: [
    { id: 'lk-kg', name: 'Kurunegala' },
    { id: 'lk-px', name: 'Puttalam' },
  ],
  Northern: [
    { id: 'lk-ja', name: 'Jaffna' },
    { id: 'lk-kl', name: 'Kilinochchi' },
    { id: 'lk-mb', name: 'Mannar' },
    { id: 'lk-mp', name: 'Mullaitivu' },
    { id: 'lk-va', name: 'Vavuniya' },
  ],
  Sabaragamuwa: [
    { id: 'lk-ke', name: 'Kegalle' },
    { id: 'lk-rn', name: 'Ratnapura' },
  ],
  Southern: [
    { id: 'lk-gl', name: 'Galle' },
    { id: 'lk-hb', name: 'Hambantota' },
    { id: 'lk-mh', name: 'Matara' },
  ],
  Uva: [
    { id: 'lk-bd', name: 'Badulla' },
    { id: 'lk-mj', name: 'Monaragala' },
  ],
  Western: [
    { id: 'lk-co', name: 'Colombo' },
    { id: 'lk-gq', name: 'Gampaha' },
    { id: 'lk-kt', name: 'Kalutara' },
  ],
};

export const SRILANKA_DISTRICTS_ID = [
  'lk-ky',
  'lk-mt',
  'lk-nw',
  'lk-ap',
  'lk-bc',
  'lk-tc',
  'lk-ad',
  'lk-pr',
  'lk-kg',
  'lk-px',
  'lk-ja',
  'lk-kl',
  'lk-mb',
  'lk-mp',
  'lk-va',
  'lk-ke',
  'lk-rn',
  'lk-gl',
  'lk-hb',
  'lk-mh',
  'lk-bd',
  'lk-mj',
  'lk-co',
  'lk-gq',
  'lk-kt',
];

export const YES_NO_DROPDOWN = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export const USER_ROLE_DROPDOWN = [
  { id: 'ADMIN', name: 'Administrator' },
  { id: 'MONITOR', name: 'Monitor' },
  { id: 'ORGANIZATION', name: 'Organization Admin' },
];

export const TOAST_STATUS = 'showToast';

export const TYPE_OF_REPORT_DROPDOWN = [
  { id: 'WEEKLY', name: 'Weekly' },
  { id: 'MONTHLY', name: 'Monthly' },
];

//messages
export const ADD_REPORT_SUCCESS = 'Report added successfully!';
export const ADD_REPORT_FAILED = 'Report upload failed!';
export const ADD_USER_SUCCESS = 'User created successfully!';
export const UPDATE_USER_SUCCESS = 'User updated successfully!';
export const ADD_USER_FAILED = 'User creation failed!';
export const ADD_INCIDENT_SUCCESS = 'Incident added successfully!';
export const ADD_INCIDENT_FAILED = 'Incident creation failed!';
export const UPDATE_INCIDENT_SUCCESS = 'Incident updated successfully!';
export const UPDATE_INCIDENT_FAILED = 'Incident update failed!';
export const ADD_OBSERVATION_SUCCESS = 'Observation uploaded successfully!';
export const ADD_OBSERVATION_FAILED = 'Observation upload failed!';
export const OVERRIDE_OBSERVATION_SUCCESS =
  'The Key observation report has been successfully overridden!';
export const OVERRIDE_OBSERVATION_FAILED =
  'Overriding of the existing Key observations report was not successful!';
export const UPDATE_BULK_REPORT_SUCCESS = 'Bulk report updated successfully!';
export const UPDATE_BULK_REPORT_FAILED = 'Bulk report update failed!';

//dashboard
export const SM_PLATFORM_NAME = 'youtube';
export const SM_CONTENT_NAME = 'video';
