import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../Layout';
import UserForm from '../../templates/userForm';
import { API_URL, sendAuthenticatedGetRequest } from '../../../services/http';
import CircularProgress from '@mui/material/CircularProgress';

export default function EditUser() {
  const { userId } = useParams<{ userId: string }>();
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await sendAuthenticatedGetRequest(`${API_URL}/users/${userId}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Failed to fetch user data', error);
      }
    };

    fetchUserData();
  }, [userId]);

  return (
    <Layout>
      {userData ? <UserForm isEdit={true} userData={userData} /> : <CircularProgress />}
    </Layout>
  );
}
