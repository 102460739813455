import React, { useState } from 'react';
import { API_URL, sendAuthenticatedPutRequest } from '../../../services/http';
import Switch from '@mui/material/Switch';

interface ToggleSwitchProps {
  status: string;
  userId: string;
  onSuccess: (message: string, severity: 'success' | 'error') => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ status, userId, onSuccess }) => {
  const [toggleStatus, setToggleStatus] = useState(status === 'ACTIVATED');

  const handleToggle = () => {
    const newStatus = toggleStatus ? 'DEACTIVATE' : 'ACTIVATE';
    setToggleStatus(!toggleStatus); // Optimistically update the toggle status
    sendAuthenticatedPutRequest(`${API_URL}/users/${userId}?action=${newStatus}`, {})
      .then(res => {
        if (res.status === 200) {
          onSuccess(
            `User ${newStatus === 'ACTIVATE' ? 'activated' : 'deactivated'} successfully`,
            'success'
          );
        } else {
          setToggleStatus(!toggleStatus); // Reverting back the toggle status on failure
          onSuccess('Failed to update status', 'error');
        }
      })
      .catch(() => {
        setToggleStatus(!toggleStatus); // Reverting back the toggle status on failure
        onSuccess('Failed to update status', 'error');
      });
  };

  return (
    <Switch
      checked={toggleStatus}
      onChange={handleToggle}
      color="primary"
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};

export default ToggleSwitch;
