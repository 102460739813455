import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

import {
  API_URL,
  sendAuthenticatedGetRequest,
  sendAuthenticatedGetRequestWithResponseType,
  sendAuthenticatedPutRequest,
} from '../../../services/http';
import Layout from '../Layout';
import InputField from '../../ui/inputField';
import DataTable from '../../ui/dataTable/index';
import Toastr from '../../ui/toastr';
import {
  STATUS_ALL,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  TOAST_STATUS,
  USER_ROLE_ADMIN,
} from '../../../Constants';
import DropDownField from '../../ui/dropdown';
import axios from 'axios';
import { sortArrayAscending } from '../../../services/common';
import { FaFileExport, FaFilter, FaRedo } from 'react-icons/fa';
import useUserData from '../../../services/auth/useUserData';

const columnData = [
  {
    field: 'incidentId',
    headerName: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    field: 'link',
    headerName: 'Link',
    flex: 2,
    filterable: false,
    sortable: false,
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 2,
    filterable: false,
    sortable: false,
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'language',
    headerName: 'Language',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'postType',
    headerName: 'Post Type',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'reportedBy',
    headerName: 'Recorded By',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'incidentStatus',
    headerName: 'Status',
    flex: 1,
    filterable: false,
    sortable: false,
  },
];

const Index = () => {
  const history = useHistory();
  const { userData } = useUserData();

  const [searchText, setSearchText] = useState<string>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabType, setTabType] = useState<string>(STATUS_ALL);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [urlParams, setUrlParams] = useState<any>({ sortOrder: 'DESC', sortKey: 'updatedDate' });
  const [showAlert, setShowAlert] = React.useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [statusAllCount, setStatusAllCount] = useState(-1);
  const [statusPendingCount, setStatusPendingCount] = useState(-1);
  const [statusApprovedCount, setStatusApprovedCount] = useState(-1);
  const [statusRejectedCount, setStatusRejectedCount] = useState(-1);

  const [categoryList, setCategoryList] = useState<any>([]);
  const [sourceList, setSourceList] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  const [typeOfContentList, setTypeOfContentList] = useState<any>([]);
  const [recordedByFilter, setRecordedByFilter] = useState<string>();
  const [idFilter, setIdFilter] = useState<string>();
  const [linkFilter, setLinkFilter] = useState<string>();

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [error, setError] = useState('');

  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [selectedSources, setSelectedSources] = useState<any[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<any[]>([]);
  const [selectedPostTypes, setSelectedPostTypes] = useState<any[]>([]);

  const getDropDownData = async () => {
    const categoryReq = sendAuthenticatedGetRequest(`${API_URL}/categories`);
    const sourceReq = sendAuthenticatedGetRequest(`${API_URL}/sources`);
    const languageReq = sendAuthenticatedGetRequest(`${API_URL}/languages`);
    const typeOfContentReq = sendAuthenticatedGetRequest(`${API_URL}/socialMediaPlatformContents`);

    await axios.all([categoryReq, sourceReq, languageReq, typeOfContentReq]).then(
      axios.spread(function (
        categoryReq: any,
        sourceReq: any,
        languageReq: any,
        typeOfContentReq: any
      ) {
        setCategoryList(sortArrayAscending(categoryReq.data.categories));
        setSourceList(sortArrayAscending(sourceReq.data.sources));
        setLanguageList(sortArrayAscending(languageReq.data.languages));
        setTypeOfContentList(typeOfContentReq.data.socialMediaPlatformContents);
      })
    );
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const location: any = useLocation();
  const counts = useSelector((state: RootState) => state.incidents.incidentCounts);

  useEffect(() => {
    getDropDownData();
  }, []);

  useEffect(() => {
    if (counts !== undefined) {
      setStatusAllCount(counts.ALL);
      setStatusPendingCount(counts.PENDING);
      setStatusApprovedCount(counts.APPROVED);
      setStatusRejectedCount(counts.REJECTED);
    }
  }, [counts]);

  useEffect(() => {
    if (location.state != null) {
      if (location.state.toastStatus == TOAST_STATUS) {
        setShowAlert(true);
        setToastMessage(location.state.toastMessage);
        setSeverity(location.state.severity);
      }
    }
  }, [location]);

  useEffect(() => {
    let params = {};

    params = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    if (tabType !== STATUS_ALL) {
      params = { sortOrder: 'DESC', sortKey: 'updatedDate', status: tabType };
    }
    if (searchText) {
      params = { sortOrder: 'DESC', sortKey: 'updatedDate', searchKey: searchText.trim() };
      if (tabType !== STATUS_ALL) {
        params = {
          sortOrder: 'DESC',
          sortKey: 'updatedDate',
          status: tabType,
          searchKey: searchText.trim(),
        };
      }
    }
    setUrlParams(params);
  }, [searchText]);

  const tabChangeHandler = (index: number, selectedStatus: string) => {
    setTabIndex(index);
    setTabType(selectedStatus);
    setSelectedRows([]);

    applyFilter(selectedStatus);
  };

  const reportIncidents = () => {
    const data = { ids: selectedRows };
    sendAuthenticatedPutRequest(`${API_URL}/incidents/bulk/report`, data).then(() => {
      tabChangeHandler(0, STATUS_ALL);
    });
  };

  const rowClickHandler = (row: any) => {
    history.push('/incidents/' + row.id);
  };

  const applyFilter = (selectedStatus: string) => {
    let params: any = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    if (selectedStatus !== STATUS_ALL) {
      params = { sortOrder: 'DESC', sortKey: 'updatedDate', status: selectedStatus };
    }
    if (selectedCategories.length) {
      params.categories = selectedCategories;
    }
    if (selectedSources.length) {
      params.sources = selectedSources;
    }
    if (selectedLanguages.length) {
      params.languages = selectedLanguages;
    }
    if (selectedPostTypes.length) {
      params.postTypes = selectedPostTypes;
    }
    if (recordedByFilter) {
      params.recordedBy = recordedByFilter;
    }
    if (idFilter) {
      params.id = idFilter;
    }
    if (linkFilter) {
      params.link = linkFilter;
    }

    setUrlParams(params);
  };

  const resetFilter = () => {
    setSelectedCategories([]);
    setSelectedSources([]);
    setSelectedLanguages([]);
    setSelectedPostTypes([]);
    setRecordedByFilter('');
    setIdFilter('');
    setLinkFilter('');
    // loads the default data set
    const params: any = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    if (tabType !== STATUS_ALL) {
      params.status = tabType;
    }
    setUrlParams(params);
  };

  const handleStartDateChange = (e: { target: { value: any } }) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    if (endDate && newStartDate > endDate) {
      setError('End date must be later than start date.');
    } else {
      setError('');
    }
  };

  const handleEndDateChange = (e: { target: { value: any } }) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    if (startDate && newEndDate < startDate) {
      setError('End date must be later than start date.');
    } else {
      setError('');
    }
  };

  const validateDates = (startDate: string, endDate: string): string | null => {
    if (!startDate || !endDate) {
      return 'Start date and end date are required.';
    }
    if (new Date(startDate) > new Date(endDate)) {
      return 'End date must be later than start date.';
    }
    return null;
  };

  const initiateDownload = (responseData: Blob, startDate: string, endDate: string) => {
    const url = window.URL.createObjectURL(responseData);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `incidents_data_${startDate}_to_${endDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = async () => {
    setError('');

    // Validate dates
    const dateValidationError = validateDates(startDate, endDate);
    if (dateValidationError) {
      setError(dateValidationError);
      return;
    }

    // Show success alert
    setShowAlert(true);
    setToastMessage('Your download will start shortly');
    setSeverity('success');

    const params = { ...urlParams, startDate: startDate, endDate: endDate };
    console.log(params);

    try {
      // Send request to fetch the export file
      const response = await sendAuthenticatedGetRequestWithResponseType<Blob>(
        `${API_URL}/incidents/export`,
        params,
        'blob'
      );

      // Initiate file download
      initiateDownload(response.data, startDate, endDate);
    } catch (error) {
      setError('Failed to export data.');
    }
  };

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">Incidents</span>
          <div className="flex justify-between items-center my-8">
            <div className="grid grid-auto grid-cols-2 lg:grid-cols-4 gap-x-4 w-full">
              <DropDownField
                title="Category"
                data={categoryList}
                value={selectedCategories}
                onChangeHandler={e => setSelectedCategories(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Source"
                data={sourceList}
                value={selectedSources}
                onChangeHandler={e => setSelectedSources(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Language"
                data={languageList}
                value={selectedLanguages}
                onChangeHandler={e => setSelectedLanguages(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Post Type"
                data={typeOfContentList}
                value={selectedPostTypes}
                onChangeHandler={e => setSelectedPostTypes(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <InputField
                isHorizontal={false}
                title="ID"
                placeholder="Enter ID"
                value={idFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setIdFilter(e.target.value)}
              />
              <InputField
                isHorizontal={false}
                title="Recorded By"
                placeholder="Enter user name"
                value={recordedByFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setRecordedByFilter(e.target.value)}
              />
              <InputField
                isHorizontal={false}
                title="Link"
                placeholder="Enter link"
                value={linkFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setLinkFilter(e.target.value)}
              />
              <div className="flex items-center justify-end">
                <button
                  className="flex items-center justify-between bg-indigo-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3 mt-3"
                  type="button"
                  onClick={() => applyFilter(tabType)}
                >
                  Apply Filter
                  <FaFilter className="text-lg pl-1" />
                </button>
                <button
                  className="flex items-center justify-between bg-gray-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3 mt-3"
                  type="button"
                  onClick={resetFilter}
                >
                  Reset
                  <FaRedo className="text-lg pl-1" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-start items-right my-8">
            {selectedRows.length > 0 && (
              <button
                className="bg-red-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base mr-3"
                onClick={() => reportIncidents()}
                type="button"
              >
                Report to SM Platform
              </button>
            )}
            {tabIndex == 2 && (
              <Link to="/incident/addBulkReport">
                <button
                  className="bg-indigo-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base mr-3"
                  type="button"
                >
                  Bulk Report to SM Platform
                </button>
              </Link>
            )}
            <Link to="/incidents/add">
              <button
                type="button"
                className="bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base mr-3"
              >
                Add Incident
              </button>
            </Link>
          </div>

          <div className="flex mb-5">
            <button
              onClick={() => tabChangeHandler(0, STATUS_ALL)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 0 && `border-b-4 border-indigo-500 text-indigo-500`
              }`}
            >
              All {statusAllCount !== -1 && `(` + statusAllCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(1, STATUS_PENDING)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 1 && `border-b-4 border-indigo-500 text-indigo-500`
              }`}
            >
              Pending {statusPendingCount !== -1 && `(` + statusPendingCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(2, STATUS_APPROVED)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 2 && `border-b-4 border-indigo-500 text-indigo-500`
              }`}
            >
              Approved {statusApprovedCount !== -1 && `(` + statusApprovedCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(3, STATUS_REJECTED)}
              className={`cursor-pointer focus:outline-none pb-1 ${
                tabIndex === 3 && `border-b-4 border-indigo-500 text-indigo-500`
              }`}
            >
              Rejected {statusRejectedCount !== -1 && `(` + statusRejectedCount + `)`}
            </button>
          </div>
          {/* Replicated for all statuses since MUI DataGrid
          doesn't support resetting page to zero */}
          {tabType === STATUS_ALL && (
            <DataTable
              url="/incidents"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
            />
          )}
          {tabType === STATUS_PENDING && (
            <DataTable
              url="/incidents"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
            />
          )}
          {tabType === STATUS_APPROVED && (
            <DataTable
              url="/incidents"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              hasCheckbox={true}
              onRowClickHandler={rowClickHandler}
              onRowSelectionHandler={(ids: any) => setSelectedRows(ids)}
            />
          )}
          {tabType === STATUS_REJECTED && (
            <DataTable
              url="/incidents"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
            />
          )}
          <Toastr
            autoHideDuration={6000}
            onClose={handleClose}
            open={showAlert}
            severity={severity && severity}
            message={toastMessage}
          />
        </div>
        {userData.role === USER_ROLE_ADMIN && (
          <div className="flex justify-between items-start my-8">
            <div className="grid grid-auto grid-cols-2 lg:grid-cols-4 gap-x-4 w-full">
              <InputField
                isHorizontal={false}
                title="Start Date"
                placeholder="Select start date"
                type="date"
                value={startDate}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={handleStartDateChange}
              />
              <InputField
                isHorizontal={false}
                title="End Date"
                placeholder="Select end date"
                type="date"
                value={endDate}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={handleEndDateChange}
              />
              <div className="flex items-center justify-start">
                <button
                  className="flex items-center justify-between bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3 mt-3"
                  type="button"
                  onClick={handleExport}
                >
                  Export
                  <FaFileExport className="text-lg pl-1" />
                </button>
              </div>
              {error && <div className="col-span-2 text-red-500 text-sm mt-2">{error}</div>}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Index;
