import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';

import { API_URL, sendPostRequest } from '../../../services/http/index';
import useToken from '../../../services/auth/useToken';
import useUserData from '../../../services/auth/useUserData';
import Link from '@mui/material/Link';
import InputField from '../../ui/inputField';

const LoginSchema = Yup.object({
  email: Yup.string().trim().email('Email must be valid').required('Email address is required'),
  password: Yup.string().required('Password is required'),
});

const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState();

  const { setUserData } = useUserData();
  const { setToken } = useToken();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowError(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showError]);

  return (
    <div className="login bg-repeat-round" style={{ backgroundImage: `url(${'/bg_login.jpg'})` }}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={async values => {
          setLoading(true);
          const data = {
            email: values.email,
            password: values.password,
          };

          await sendPostRequest(`${API_URL}/auth/login`, JSON.stringify(data))
            .then((res: any) => {
              setLoading(false);
              setToken(res.data.userAccessToken);
              setUserData({ userId: res.data.userId, name: res.data.name, role: res.data.role });
              window.location.replace('/');
            })
            .catch(err => {
              setLoading(false);
              setErrorMessage(err.response.data.message);
              setShowError(true);
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <div className="bg-white p-8 rounded-md">
            <img
              className="object-contain w-52 mx-auto mb-14"
              src="/logo.png"
              alt="hashtag generation logo"
            />
            <span className="font-semibold text-gray-700 text-2xl">SIGN IN</span>
            <div className="my-8">
              <InputField
                isHorizontal={false}
                title="Email"
                type="email"
                value={values.email}
                placeholder="Enter your email"
                containerClassNames="w-80 mt-5"
                onChangeHandler={handleChange('email')}
                formikError={errors.email}
                formikTouch={touched.email}
              />
              <InputField
                isHorizontal={false}
                title="Password"
                type="password"
                value={values.password}
                placeholder="Enter your password"
                containerClassNames="w-80 mt-5"
                onChangeHandler={handleChange('password')}
                formikError={errors.password}
                formikTouch={touched.password}
              />
              {showError && <div className="w-80 text-red-500 text-xs mt-1">{errorMessage}</div>}
              {/*TODO enable once the email server is ready*/}
              {/*<div className="my-1">*/}
              {/*  <Link*/}
              {/*    className="mt-1 text-xs float-right"*/}
              {/*    href="/forgot-password"*/}
              {/*    underline="hover"*/}
              {/*  >*/}
              {/*    {'Forgot Password?'}*/}
              {/*  </Link>*/}
              {/*</div>*/}
            </div>
            <button
              className="bg-indigo-500 focus:outline-none w-24 h-10 mx-auto rounded-lg text-white text-base flex justify-center items-center"
              onClick={() => handleSubmit()}
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress color="inherit" size={20} /> : 'Login'}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
